.dropdown-scrollbar {
    height: 200px;
    overflow: scroll;
}

.testeo{
    color:red;
}

.testeo:hover{
    color:brown;
}

