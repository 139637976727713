@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap);
.LogoDos{
    max-width: 200px;
    max-height: 60px;
}

@media screen and (max-width: 300px) {
    .button-text {
        display: none;
    }
}

.h5{
    font-size: 1.00rem;
}

::-webkit-scrollbar {
    display: none;
}

.fuenteNavbar{
    font-size: 20px;
    color: #000;
    padding-left: 5px;
    
}
.fuenteNavbar:hover{
    color: rgb(253, 0, 0);
}

.cardMenu{

    text-align: center;
}


  @media screen and (max-width: 760px) {
    #catalog {

        overflow: auto;
        display:inline-block;
      }
}
.Logo{
    max-width: 250px;
    max-height: 100px;
}

.testeandoTam {
    height: 350px;
}


@media screen and (max-width: 750px) {
  
    .testeandoTam {
        height: 200px;
    }
}


@media screen and (max-width: 300px) {
    .button-text {
        display: none;
    }
  
}

.h5{
    font-size: 1.00rem;
}

::-webkit-scrollbar {
    display: none;
}

.colorMenu {
    color:black;
    font-weight: 500;
}

.colorMenu:hover {
    color:red;
    font-weight: 500;
}

.strikethrough {
    position: relative;
    color: gray;
}
 
.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color:gray;
     
    -webkit-transform:rotate(-10deg);
    transform:rotate(-10deg);
}

.login-box {
    margin-top: 75px;
    height: auto;
    background: #1A2226;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-key {
    height: 200px;
    font-size: 80px;
    line-height: 100px;
    background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-title {
    margin-top: 25px;
    text-align: center;
    font-size: 30px;
    letter-spacing: 2px;
    margin-top: 15px;
    font-weight: bold;
    color: #000;
}

.login-form {
    margin-top: 25px;
    text-align: left;
}


.footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#282d32;
  }
  
  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
    color: #fff;
  }
  
.dropdown-scrollbar {
    height: 200px;
    overflow: scroll;
}

.testeo{
    color:red;
}

.testeo:hover{
    color:brown;
}


.confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    box-sizing: border-box;

    opacity: 0;
    -webkit-animation-name: confirm---open;
            animation-name: confirm---open;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm--close {
    -webkit-animation-name: confirm---close;
            animation-name: confirm---close;
}

.confirm__window {
    width: 100%;
    max-width: 600px;
    background: white;
    font-size: 14px;
    font-family: 'Noto Sans', sans-serif;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    opacity: 0;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    -webkit-animation-name: confirm__window---open;
            animation-name: confirm__window---open;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}

.confirm__titlebar,
.confirm__content,
.confirm__buttons {
    padding: 1.25em;
}

.confirm__titlebar {
    background: #222222;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.confirm__title {
    font-weight: bold;
    font-size: 1.1em;
}

.confirm__close {
    background: none;
    outline: none;
    border: none;
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
    color: #ffffff;
    transition: color 0.15s;
}

.confirm__close:hover {
    color: #ff0000;
    cursor: pointer;
}

.confirm__content {
    line-height: 1.8em;
}

.confirm__buttons {
    background: #eeeeee;
    display: flex;
    justify-content: flex-end;
}

.confirm__button {
    padding: 0.4em 0.8em;
    border: 2px solid #ff0000;
    border-radius: 5px;
    background: #ffffff;
    color: #ff0000;
    font-weight: bold;
    font-size: 1.1em;
    font-family: 'Noto Sans', sans-serif;
    margin-left: 0.6em;
    cursor: pointer;
    outline: none;
}

.confirm__button--fill {
    background: #ff0000;
    color: #ffffff;
}

.confirm__button:focus {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes confirm---open {
    from { opacity: 0 }
    to { opacity: 1 }
}

@keyframes confirm---open {
    from { opacity: 0 }
    to { opacity: 1 }
}

@-webkit-keyframes confirm---close {
    from { opacity: 1 }
    to { opacity: 0 }
}

@keyframes confirm---close {
    from { opacity: 1 }
    to { opacity: 0 }
}

@-webkit-keyframes confirm__window---open {
    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes confirm__window---open {
    to {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

