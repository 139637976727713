.LogoDos{
    max-width: 200px;
    max-height: 60px;
}

@media screen and (max-width: 300px) {
    .button-text {
        display: none;
    }
}

.h5{
    font-size: 1.00rem;
}

::-webkit-scrollbar {
    display: none;
}

.fuenteNavbar{
    font-size: 20px;
    color: #000;
    padding-left: 5px;
    
}
.fuenteNavbar:hover{
    color: rgb(253, 0, 0);
}

.cardMenu{

    text-align: center;
}


  @media screen and (max-width: 760px) {
    #catalog {

        overflow: auto;
        display:inline-block;
      }
}