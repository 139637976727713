.Logo{
    max-width: 250px;
    max-height: 100px;
}

.testeandoTam {
    height: 350px;
}


@media screen and (max-width: 750px) {
  
    .testeandoTam {
        height: 200px;
    }
}


@media screen and (max-width: 300px) {
    .button-text {
        display: none;
    }
  
}

.h5{
    font-size: 1.00rem;
}

::-webkit-scrollbar {
    display: none;
}

.colorMenu {
    color:black;
    font-weight: 500;
}

.colorMenu:hover {
    color:red;
    font-weight: 500;
}

.strikethrough {
    position: relative;
    color: gray;
}
 
.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color:gray;
     
    -webkit-transform:rotate(-10deg);
    -moz-transform:rotate(-10deg);
    -ms-transform:rotate(-10deg);
    -o-transform:rotate(-10deg);
    transform:rotate(-10deg);
}

.login-box {
    margin-top: 75px;
    height: auto;
    background: #1A2226;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-key {
    height: 200px;
    font-size: 80px;
    line-height: 100px;
    background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-title {
    margin-top: 25px;
    text-align: center;
    font-size: 30px;
    letter-spacing: 2px;
    margin-top: 15px;
    font-weight: bold;
    color: #000;
}

.login-form {
    margin-top: 25px;
    text-align: left;
}
